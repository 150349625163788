import * as React from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

export interface CookCanvasWidgetProps {
  color?: string;
  background?: string;
}

namespace S {
  export const Container = styled.div<{ color: string; background: string }>`
    height: 100%;
    background-color: ${(p) => p.background};
    background-size: 50px 50px;
    display: flex;

    > * {
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
    background-image: radial-gradient(${(p) => p.color} 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -19px -19px;

   
  `;

  export const Expand = css`
    html,
    body,
    #root {
      height: 100%;
    }
  `;
}

export class CookCanvasWidget extends React.Component<
  React.PropsWithChildren<CookCanvasWidgetProps>
> {
  render() {
    return (
      <>
        <Global styles={S.Expand} />
        <S.Container
          background={this.props.background || "#fafafc"}
          color={this.props.color || "rgba(60,60,60, 0.3)"}
        >
          {this.props.children}
        </S.Container>
      </>
    );
  }
}

// background={this.props.background || "rgb(255, 255, 255)"}
//           color={this.props.color || "rgba(60,60,60, 0.05)"}