import { Component } from "react";
import { Container } from "react-bootstrap";
import "./Home.css";

// var api = "";
// if (process.env.NODE_ENV !== "production") {
//   api =
//     "http://" +
//     process.env.REACT_APP_WEBAPI_HOST +
//     ":" +
//     process.env.REACT_APP_WEBAPI_PORT;
// }

// const webapi = api + "/api/search?query=";
// const pdfapi = api + "/api/files/download?sha=";

export default class Home extends Component {


  render() {
    return (
      <Container fluid className="home">
       
      </Container>
    );
  }
}
