import { Component } from "react";
import  MenuItem  from "./MenuItem"
import { Col, Row } from "react-bootstrap";

import "../Recipes.css";

const INGREDIENT="Ingredient"
const USTENSILE="Ustensile"
const ETAPE="Etape"

export default class MenuAddRecipe extends Component {

  render() {
    return (
      <>
        <Row className="w-100">
            <Col xs={12} sm={4} md={4} lg={4}>
            <MenuItem onHandleChange={this.props.onHandleChange} name={INGREDIENT} placeholder={"un "+ INGREDIENT} jsonfile="Ingredients.json" />
            </Col >
            <Col xs={12} sm={4} md={4} lg={4}>
            <MenuItem onHandleChange={this.props.onHandleChange} name={USTENSILE} placeholder={"un "+ USTENSILE} jsonfile="Ustensiles.json" />
            </Col>
            <Col xs={12} sm={4} md={4} lg={4}>
            <MenuItem onHandleChange={this.props.onHandleChange} name={ETAPE} placeholder={"une "+ ETAPE}jsonfile="Etapes.json" />
            </Col>
          </Row>
      </>
    );
  }
}
