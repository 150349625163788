import { CookPortModel } from './CookPortModel.tsx';
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";

export class CookPortFactory extends AbstractReactFactory{
	constructor(type = 'cookport') {
		super(type);
	}

	generateModel(): CookPortModel {
		return new CookPortModel({
			name: 'unknown'
		});
	}
}
