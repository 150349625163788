import { Component } from "react";
import { Container } from "react-bootstrap";
import './Section.css'

class Section extends Component {
  render() {
      return (
        <section style={this.props.style}>
          <Container fluid={this.props.fluid} className={this.props.classname}>
            {this.props.children}
          </Container>
        </section>
      );
    } 
}

export default Section;
