import { Component } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { createFilter } from "react-select";
import "../Recipes.css";
import React from "react";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// const options = [{ label: "test", value: "test" }];

function normalize(input) {
  return input
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export default class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.filter = this.filter.bind(this);
    // this.onFocus = this.onFocus.bind(this);
    this.state = { data: [], name_label: "" };
    this.LoadData();
  }

  filter = (search) => {  
    return this.state.data.filter((i) => i.search.includes(search)).sort((a, b) =>  b.search.startsWith(search) - a.search.startsWith(search)
    );
  }
    

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (inputValue) {
        resolve((e) => this.filter(normalize(inputValue)));
      } else {
        resolve(this.state.data.slide(1, 10));
      }
    });

  LoadData() {
    fetch(this.props.jsonfile).then((response) => {
      response.json().then((data) => {
        data = data.map((s) => ({
          label: capitalizeFirstLetter(s.label.trim()),
          value: s.value.trim(),
          search: normalize(s.label),
        }));
        this.setState({
          ...this.state,
          data: data,
        });
      });
    });
  }

  handleChange(e) {
    this.setState({
      name_label: e ? e.label : "",
    });
  }

  onFocus(e) {
    this.setState({ clearvalue: "" });
  }

  render() {
    return (
      <>
        <div className="RecetteNeed">
          <label className="w-100">
            {this.props.name}
            <div className="d-flex w-100">
              <AsyncCreatableSelect
                onChange={this.handleChange}
                // options={options}
                defaultOptions={this.state.data.slice(0, 100)}
                loadOptions={this.promiseOptions}
                isClearable={true}
                placeholder={"Taper pour chercher " + this.props.placeholder.toLowerCase() + " ..."}
                noOptionsMessage={() => "Taper pour chercher..."}
                filterOption={createFilter({
                  ignoreAccents: true,
                  matchFromStart: true,
                  ignoreCase: true,
                  trim: true,
                })}
              />
              <button
                className="Button "
                onClick={(e) =>
                  this.state.name_label
                    ? this.props.onHandleChange(
                        e,
                        this.props.name,
                        this.state.name_label
                      )
                    : null
                }
              >
                +
              </button>
            </div>
          </label>
        </div>
      </>
    );
  }
}
