import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Form from "react-bootstrap/Form";

namespace S {
  export const Expand = css`
    html,
    body,
    #root {
      height: 100%;
    }
  `;

  export const qtContainer = styled.div`
    border: none;
    font-size: 13px;
    color: white;
    padding: 8px;
    border-bottom : solid 1px white;
  `;
}

export interface CookSequentialProps {
  qtContainer?: any;
}

export default class CookSequential extends React.Component<CookSequentialProps> {


  render() {
    return (
      <S.qtContainer>
        <Form>
          <Form.Check
            type="switch"
            // id="custom-switch"
            label="Action séquentielle"
            onChange={this.props.Onseq}
            checked={this.props.seq}
          />
        </Form>
      </S.qtContainer>
    );
  }
}
