import {
  DefaultPortModel,
  DefaultPortModelOptions,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";

import { DeserializeEvent } from "@projectstorm/react-canvas-core";
import { CookLinkModel } from "../CookLink/CookLinkModel.tsx";

export class CookPortModel extends DefaultPortModel {
  constructor(options, name?, label?, islabled = false) {
    if (!!name) {
      options = {
        in: !!options,
        name: name,
        label: label,
        islabled: islabled,
      };
    }
    options = options as DefaultPortModelOptions;
    super({
      label: options.label || options.name,
      alignment: options.in
        ? PortModelAlignment.LEFT
        : PortModelAlignment.RIGHT,
      type: "cookport",
      islabled: islabled,
      ...options,
    });
  }

  isAlreadyConnected(targetPort: DefaultPortModel) {
    let connected = false;
    let links = this.getLinks();

    Object.values(links).forEach((l) => {

      if (l.getTargetPort() && l.getTargetPort().getParent().getID() === targetPort.getParent().getID())
        connected = true;
    });

    if (connected) return connected;

    Object.values(targetPort.getParent().getPorts()).forEach((P) => {
      Object.values(P.getLinks()).forEach((L) => {
        if (L.getTargetPort().getParent().getID() === this.getParent().getID())
          connected = true;
      });
    });
    return connected;
    
  }

  canLinkToPort(targetPort: DefaultPortModel) {
    return (
      this.getParent().getID() !== targetPort.getParent().getID() &&
      this.getName() === "out" &&
      targetPort.getName() === "in" &&
      !this.isAlreadyConnected(targetPort)
    );
  }

  createLinkModel(): any {
    // console.log(this.options.islabled)
    return new CookLinkModel({
      type: this.options.islabled ? "cooklink-labeled" : "cooklink",
    });
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.options.in = event.data.in;
    this.options.label = event.data.label;
    this.options.type = event.data.type;
    this.options.islabled = event.data.islabled;
  }

  serialize() {
    return {
      ...super.serialize(),
      in: this.options.in,
      label: this.options.label,
      islabled: this.options.islabled,
    };
  }
}
