import Section from "../Section/Section";

function Help() {

  const child = (
    <div className="row align-items-center my-5">
          <div className="col-lg-12">
            <h1 className="font-weight-light">Help</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
  );

  return (
    <Section
      fluid={false}
      name="help"
      classname="p-5"
      children={child}
    ></Section>
  );
}

export default Help;

