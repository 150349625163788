import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Select from "react-select";

namespace S {
  export const Expand = css`
    html,
    body,
    #root {
      height: 100%;
    }
  `;

  export const qtContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    border: none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    font-size: 12px;
  `;

  export const qtInput = styled.input`
    padding: 0px;
    display: flex;
    flex-shrink: 0;
    overflow: auto;
    width: 50px;
    border: none;
    margin-right: 1px;
    text-indent: 5px;
    text-align: center;
    border-top-left-radius: 2px;
  `;
}

export interface CookQuantityProps {
  qtContainer?: any;
  qtInput?: any;
  qtLabel?: any;
}

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    minWidth: 80,
    border: "none",
    borderTopRightRadius: 2,
  }),
};

const onlyNumberKey = (evt) => {
  // Only ASCII character in that range allowed
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 45 || ASCIICode > 57 ))
    evt.preventDefault();

  return true;
};

export default class CookQuantity extends React.Component<CookQuantityProps> {
  constructor(props) {
    super(props);
    // this.onFocus = this.onFocus.bind(this);
    this.state = { data: [] };
    this.LoadData();
    // console.log(this.props.node.options.unit)
  }

  LoadData() {
    fetch("Quantities.json").then((response) => {
      response.json().then((data) => {
        data = data.map((s) => ({
          label: s.label.trim(),
          value: s.value.trim(),
        }));
        this.setState({
          ...this.state,
          data: data,
        });
      });
    });
  }

  render() {
    return (
      <S.qtContainer>
        <S.qtInput
          type="text"
          placeholder="Qt"
          onKeyPress={onlyNumberKey}
          onChange={this.props.onQuantity}
          defaultValue={this.props.node.options.qte}
        ></S.qtInput>
        <Select
          styles={customStyles}
          options={this.state.data}
          placeholder="Unité"
          isClearable={true}
          defaultOptions={this.props.node.options.unit}
          onChange={this.props.onUnit}
          defaultValue={
            this.props.node.options.unit
              ? {
                  label: this.props.node.options.unit,
                  value: this.props.node.options.unit,
                }
              : undefined
          }
        ></Select>
      </S.qtContainer>
    );
  }
}
