import { NodeModel } from "@projectstorm/react-diagrams";
import { CookPortModel } from "../CookPort/CookPortModel.tsx";

const INGREDIENT = "Ingredient";
const USTENSILE = "Ustensile";
const ETAPE = "Etape";
const FIN = "Fin";
/**
 * Example of a custom model using pure javascript
 */

function getcolor(type) {
  switch (type) {
    case INGREDIENT:
      return "#FFB600";
    case USTENSILE:
      return "#2CCCC3";
    case ETAPE:
      return "#6688CC";
    case FIN:
      return "#E60576";
    default:
      return "#000000";
  }
}
export class CookNodeModel extends NodeModel {
  constructor(options = {}) {
    // console.log(options);
    if (typeof options === "string") {
      options = {
        name: options,
      };
    }
    super({
      name: "Untitled",
      allowDelete: true,
      qte: undefined,
      unit: undefined,
      seq: false,
      type: options.type,
      ...options,
    });
    this.prev = { ing: [], prep: [], ust: [], timings: [] };
    // this.color = options.color || { options: "red" };
    let type = this.getType();
    // console.log(this);

    if ([INGREDIENT, ETAPE, FIN].includes(type)) {
      this.addPort(
        new CookPortModel({
          in: true,
          name: "in",
        })
      );
    }

    if ([INGREDIENT, USTENSILE].includes(type)) {
      this.addPort(
        new CookPortModel({
          in: false,
          name: "out",
        })
      );
    }

    if (type === ETAPE) {
      this.addPort(
        new CookPortModel({
          in: false,
          name: "out",
          islabled: true,
        })
      );
    }

    // setup an in and out port
    this.options.color = getcolor(this.getType());
    this.isBranch = this.isBranch.bind(this);
    this.getPreviousNodes = this.getPreviousNodes.bind(this);
    this.getNextNodes = this.getNextNodes.bind(this);
    this.describe = this.describe.bind(this);
    this.getconnected = this.getconnected.bind(this);
  }

  getconnected() {
    this.prev.ing = Object.values(this.getPort("in").getLinks())
      .map((e) => {
        if (e.sourcePort.getParent().getType() === INGREDIENT)
          return e.sourcePort.getParent();
        return null;
      })
      .filter((e) => e);

    this.prev.ust = Object.values(this.getPort("in").getLinks())
      .map((e) => {
        if (e.sourcePort.getParent().getType() === USTENSILE)
          return e.sourcePort.getParent();
        return null;
      })
      .filter((e) => e);

    this.prev.prep = Object.values(this.getPort("in").getLinks())
      .map((e) => {
        if (e.sourcePort.getParent().getType() === ETAPE)
          return e.sourcePort.getParent();
        return null;
      })
      .filter((e) => e);

    if (this.getType() === ETAPE && this.getPort("out")) {
      this.prev.timings = Object.values(this.getPort("out").getLinks())
        .map((e) => {
          return this.normaliseTiming(e.labels[0].value);
        })
        .filter((e) => e);
    }
  }

  isBranch() {
    // console.log(node);
    return this.getPreviousNodes().length > 1 || this.getNextNodes().length > 1;
  }

  getPreviousNodes() {
    let listnodes = [];
    if (this.getPort("in")) {
      let links = Object.values(this.getPort("in").getLinks());
      links.forEach((l) => {
        let node = l.getSourcePort().getParent();
        if (node.getType() === ETAPE) {
          listnodes.push(node);
        }
      });
    }
    return listnodes;
  }

  getNextNodes() {
    let listnodes = [];

    if (this.getPort("out")) {
      let links = Object.values(this.getPort("out").getLinks());
      links.forEach((l) => {
        if (
          l.getTargetPort().getParent().getType() === ETAPE ||
          l.getTargetPort().getParent().getType() === INGREDIENT
        ) {
          listnodes.push(l.getTargetPort().getParent());
        }
      });
    }

    return listnodes;
  }

  normaliseTiming(time) {
    let match = /^([0-9]+)(S|s|sec)$/.exec(time);
    if (match) {
      return match[1] / 60;
    }

    match = /^([0-9]+)(M|m|min)$/.exec(time);
    if (match) return match[1];

    match = /^([0-9]+)(H|h)$/.exec(time);
    if (match) return match[1] * 60;

    return null;
  }

  describe(prevActions) {
    let dis = this.options.name + " ";

    

    // if (prep.length && ing.length) dis += " à ";

    if (this.prev.ing.length === 1) dis += this.prev.ing[0].options.name;

    if (this.prev.ing.length >= 2)
      dis +=
        this.prev.ing
          .slice(0, this.prev.ing.length - 1)
          .map((e) => {
            return e.options.name;
          })
          .join(", ") +
        " et " +
        this.prev.ing[this.prev.ing.length - 1].options.name;

    if (this.isBranch() && prevActions.length > 1) {
      if (prevActions.length === 1) {
        dis +=
          (this.prev.ing.length > 0 ? " à" : " ") +
          " la préparations de l'étape " +
          prevActions[0];
      } else {
        dis +=
          (this.prev.ing.length > 0 ? " aux" : " les") +
          " préparations des étapes ( " +
          prevActions.join(", ") +
          " )";
      }
    }

    if (this.prev.ust.length)
      if (this.prev.prep.length || this.prev.ing.length)
        dis += " dans " + this.prev.ust[0].options.name;
      else dis += " " + this.prev.ust[0].options.name;

    if (this.prev.timings.length & (this.prev.timings[0] > 5))
      dis += " pendant " + this.prev.timings[0] + " min ";
    // console.log({ name: this.options.name, description: dis });
    return { name: this.options.name, description: dis };
  }

  serialize() {
    return {
      ...super.serialize(),
      name: this.options.name,
      color: this.options.color,
      allowDelete: this.options.allowDelete,
      qte: this.options.qte,
      unit: this.options.unit,
      seq: this.options.seq,
    };
  }

  deserialize(event) {
    super.deserialize(event);
    this.options.name = event.data.name;
    this.options.type = event.data.type;
    this.options.color = event.data.color;
    this.options.allowDelete = event.data.allowDelete;
    this.options.qte = event.data.qte;
    this.options.unit = event.data.unit;
    this.options.seq = event.data.seq;

    // setup an in and out port
    this.options.color = getcolor(this.options.type);
  }
}
