import { Component } from "react";
import ReactDOM from "react-dom";
import "./fonts.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  Navigation,
  // Footer,
  About,
  // Login,
  // Logout,
  // NotFound,
  Help,
  // Home,
  AddRecipe,
  // QuickLib
} from "./components";

var api;
if (process.env.NODE_ENV !== "production") {
  api =
    "http://" +
    process.env.REACT_APP_WEBAPI_HOST +
    ":" +
    process.env.REACT_APP_WEBAPI_PORT;
}



class App extends Component {

  constructor(props) {
    super(props);
    this.getLogin = this.getLogin.bind(this);
    this.clearCreds = this.clearCreds.bind(this);
      this.state = {
        isLoggedIn: false
      }
  };

  getLogin(islogged) {
    this.setState({ isLoggedIn: islogged })
  }

  clearCreds() {
    localStorage.removeItem('token')
    this.setState({ isLoggedIn: false })
  }

  componentDidMount() {

    let Auth = JSON.parse(localStorage.getItem('token'))
   
    if (!process.env.REACT_APP_NOLOGIN && Auth && Auth.accessToken) {
      fetch(api + "/api/user/validate",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8", // Indicates the content
            "Authorization": `Token ${Auth.accessToken}`
          },
        })
        .then((res) => { this.setState({ isLoggedIn: (res.status === 200) }) })
    } 
  }


  render() {
    return (
      <Router>
        <Navigation islogged={true} logout={this.clearCreds} />

        <Routes>
          {/* <Route exact path="/login" element={<Login getLogin={this.getLogin} />} /> */}
          {/* <Route exact path="/logout" element={<Logout clearCreds={this.clearCreds} />} /> */}

          <Route exact path="*" element={ <AddRecipe /> } />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/help" element={<Help />} /> :
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>

        {/* <Footer /> */}
      </Router>
    );

  // render() {
  //   return (
  //     <Router>
  //       <Navigation islogged={this.state.isLoggedIn || process.env.REACT_APP_NOLOGIN} logout={this.clearCreds} />

  //       <Routes>
  //         <Route exact path="/login" element={<Login getLogin={this.getLogin} />} />
  //         <Route exact path="/logout" element={<Logout clearCreds={this.clearCreds} />} />

  //         <Route exact path="/" element={(this.state.isLoggedIn || process.env.REACT_APP_NOLOGIN) ? <AddRecipe /> : <Login getLogin={this.getLogin} />} />
  //         <Route exact path="/recette" element={(this.state.isLoggedIn || process.env.REACT_APP_NOLOGIN) ? <AddRecipe /> : <Login getLogin={this.getLogin} />} />
  //         {/* <Route exact path="/library" element={(this.state.isLoggedIn) ? <QuickLib /> : <Login getLogin={this.getLogin} />} /> */}
  //         <Route exact path="/about" element={<About />} />
  //         <Route exact path="/help" element={<Help />} /> :
  //         <Route path="*" element={<NotFound />} />
  //       </Routes>

  //       {/* <Footer /> */}
  //     </Router>
  //   );
  }
}

ReactDOM.render(
  <App />, document.getElementById("root")
);

serviceWorker.unregister();
