import { Component } from "react";
import "./QuickLib.scss";
import { Iconlayerplus, Icontimes, Iconedit } from "../../assets/icons";

export default class Library extends Component {
  render() {
    console.log(this.props.color);
    return (
      <div className="Cardcontainer bordered">
        <div className="card w-100">
          <div
            className="card-header"
            style={{
              color: this.props.color.color,
              backgroundColor: this.props.color.backgroundcolor,
              textAlign: "center",
            }}
          >
            {this.props.usr.name}
          </div>

          <div className="card-body p-2">
            <div className="card-section ">
              <div className="card-section-header">Description</div>
              <div className="card-section-content">
                <p>{this.props.usr.description}</p>
              </div>
            </div>

            <div className="card-section">
              <div className="card-section-header">Collaborateurs</div>
              <div className="card-section-content">
                <ul>
                  {this.props.usr.allowedusers.map((usr) => {
                    return <li>{usr}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="card-ops">
              <Iconlayerplus className='ml-auto'  />
              <Iconedit />
              <Icontimes />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
