import { Container } from "react-bootstrap";
import "./Footer.css"

function Footer() {
  return (
    <div className="footer">
      <footer className="py-2 bg-gradient-light">

      <Container >
          <hr></hr>
          <p className="m-0 text-left text-grey">
            Copyright &copy; Corpus 2022
          </p>
       </Container>
      </footer>
    </div>
  );
}

export default Footer;
