import { Component } from "react";
import "./DetailsRecette.css";
import { Iconwindowmaximize, Iconwindowminimize } from "../../assets/icons/";

class GroupDetails extends Component {
  render() {
    return (
      <ul className="p-0">
        <li>
          {" "}
          Etape {this.props.nb}
          <ul>
            {this.props.element.map((e, idx) => {
              return (
                <li key={idx} className="ingredient">
                  {e.description}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    );
  }
}
export class DetailsRecette extends Component {
  constructor(option) {
    super(option);
    this.state = { visible: "none", reduced: true };
    this.reduce = this.reduce.bind(this);
  }
  strIngredient(element) {
    let v = "";
    if (element.qte) v += element.qte + " ";

    if (element.unit) v += element.unit + " ";

    v += element.name;
    return v;
  }

  reduce() {
    this.setState({ reduced: !this.state.reduced });
  }
  render() {
    // this.props.Etapes.forEach((element) => console.log(element));
    let Icon = this.state.reduced ? (
      <Iconwindowmaximize
        className="mt-auto mb-auto ml-auto "
        style={{ width: "25px", height: "25px", cursor: "pointer" }}
        onClick={this.reduce}
      ></Iconwindowmaximize>
    ) : (
      <Iconwindowminimize
        className="mt-auto mb-auto ml-auto "
        style={{ width: "25px", height: "25px", cursor: "pointer" }}
        onClick={this.reduce}
      ></Iconwindowminimize>
    );

    let recette ;
    if(this.props.Ingredients.length >0 || this.props.Ustensiles.length >0 || this.props.Etapes.length >0){
       recette = <div className={this.state.reduced ? " detailsRecette d-none" : " detailsRecette"} >
            <span className="bold">Ingrédients:</span>
            <ul>
              {this.props.Ingredients.map((element, idx) => {
                return (
                  <li key={idx} className="ingredient">
                    {this.strIngredient(element)}
                  </li>
                );
              })}
            </ul>

            <span className="bold">Ustensiles:</span>
            <ul>
              {this.props.Ustensiles.map((element, idx) => {
                return (
                  <li key={idx} className="ingredient">
                    {element.name}
                  </li>
                );
              })}
            </ul>

            <span className="bold">Cuisson:</span>
            <ul>
              {this.props.Etapes.map((element, idx) => {
                return (
                  <GroupDetails element={element} nb={idx + 1} key={idx} />
                );
              })}
            </ul>
          </div>
    }
    return (
      <>
        <div
          className=" Recette"
        >
          <div className="d-flex">{Icon}</div>
          {recette}
        </div>
      </>
    );
  }
}
