import { Component } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Section from "../Section/Section";
import "./Login.css";
import { sha256 } from "js-sha256";
import { Navigate } from "react-router";

var api = "";
if (process.env.NODE_ENV !== "production") {
  api =
    "http://" +
    process.env.REACT_APP_WEBAPI_HOST +
    ":" +
    process.env.REACT_APP_WEBAPI_PORT;
}

const webapi_login = api + "/api/user/connect/";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: "",
      password: "",
      errorMessage: null,
      isLoggedIn: false,
    };
  }

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [token, setToken] = useState("");

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleSubmit(event) {
    fetch(
      webapi_login +
        `?email=${encodeURIComponent(
          this.state.email
        )}&password=${encodeURIComponent(sha256(this.state.password))}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
          res.json().then((result) => {
            if (res.status === 200)
              localStorage.setItem("token", JSON.stringify(result));
            else localStorage.removeItem("token");
            // console.log(result)
            this.setState({
              isLoggedIn: res.status === 200,
              errorMessage: res.status !== 200 ? result.message : null,
            });
            this.props.getLogin(res.status === 200);
          });
      })
      .catch((err) => console.log(err));
    event.preventDefault();
  }

  render() {
    return this.state.isLoggedIn ? (
      <Navigate to="/" />
    ) : (
      <Section classname="Login">
        {this.state.errorMessage && (
          <div className="error"> {this.state.errorMessage} </div>
        )}
        <Form onSubmit={this.handleSubmit}>
          <Form.Group size="lg" controlId="email" className="mt-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password" className="mt-4">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </Form.Group>
          <Button
            size="lg"
            type="submit"
            disabled={!this.validateForm()}
            className="mt-4 w-100"
          >
            Login
          </Button>
        </Form>
      </Section>
    );
  }
}
