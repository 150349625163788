import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import {CookNodeWidget} from "./CookNodeWidget"
import {CookNodeModel} from "./CookNodeModel"

export class CookNodeFactory extends AbstractReactFactory {


  generateModel(event) {
    return new CookNodeModel();
  }

  generateReactWidget(event) {
    return <CookNodeWidget engine={this.engine} node={event.model} allowLooseLinks={false}  />;
    // return <DefaultNodeWidget engine={this.engine} node={event.model} />;
  }
}
