import {
  DefaultLinkFactory,
} from "@projectstorm/react-diagrams";

export class CookLinkFactory extends DefaultLinkFactory {
  constructor(type = "cooklink") {
    super(type);
  }

  //   generateModel(event): CookLinkModel {
  //     return new CookLinkModel();
  //   }
}
