import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Library from "./Library"
import { Iconplus } from "../../assets/icons";
import "./QuickLib.scss";

import {colors_corpus} from "./colors.js"

const libs = [
  {
    name:"test",
    description:"test description",
    allowedusers:["admin@corpus.io","admin@corpus.io","admin@corpus.io"],
    owner:true
  },
  {
    name:"test2",
    description:"test description",
    allowedusers:["admin@corpus.io","admin@corpus.io"],
    owner:false
  }
]

export default class QuickLib extends Component {
  render() {
    return (
        <Container className="libcontainer">
            <div className="newlib">
            <Iconplus style={{ width: "35px", height: "35px" }} />
            </div>
            <Row>
              { libs.map((usr,idx) => { return <Col><Library usr={usr} key={idx} color={colors_corpus[idx%colors_corpus.length]}/></Col>})}
            </Row>
        </Container>
    );
  }
}
