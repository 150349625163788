import * as React from "react";
import { PortWidget } from "@projectstorm/react-diagrams";
import CookQuantity from "./CookQuantity.tsx";
import CookSequential from "./CookSequential.tsx";

import "./CookNode.css";
import { ReactComponent as CloseIcon } from "../../../assets/icons/times.svg";
const INGREDIENT = "Ingredient";
const USTENSILE = "Ustensile";
const ETAPE = "Etape";
const FIN = "Fin";

export class CookNodeWidget extends React.Component {
  constructor(options) {
    super(options);
    this.removeMe = this.removeMe.bind(this);
    this.handleonQuantity = this.handleonQuantity.bind(this);
    this.handleonUnit = this.handleonUnit.bind(this);
    // console.log(this.props.node.options);
  }
  removeMe = () => {
    this.props.node.remove();
    this.props.engine.repaintCanvas();
  };

  handleonQuantity = (e) => {
    // console.log(e.target.value)
    this.props.node.options.qte = e.target.value;
    this.fireAction();
  };

  handleonUnit = (e) => {
    // console.log(e)
    this.props.node.options.unit = (e && e.label) ? e.label : undefined;
    this.fireAction();
  };

  handleonSeq =(e) => {
    // console.log(e.target.checked)
    this.props.node.options.seq = (e && e.target) ? e.target.checked : false;

  }

  fireAction = (e) => {
    this.props.engine.getModel().fireEvent(
      {
        node: this.props.node,
        isCreated: true,
      },
      "nodesUpdated"
    );
    return this.props.node;
  };

  render() {
    let PortOut;
    let PortIn;
    let type = this.props.node.getType();
    let Seq = 
    type === ETAPE ? (
      <CookSequential  seq={this.props.node.options.seq} Onseq={this.handleonSeq}/>
    ) : (
      ""
    );
    
    let QT =
      type === INGREDIENT ? (
        <CookQuantity
          onQuantity={this.handleonQuantity}
          onUnit={this.handleonUnit}
          node={this.props.node}
        />
      ) : (
        ""
      );

    if ([INGREDIENT, ETAPE, FIN].includes(type)) {
      PortIn = (
        <PortWidget
          className=""
          engine={this.props.engine}
          port={this.props.node.getPort("in")}
        >
          <div className="default-port left-port ">E</div>
        </PortWidget>
      );
    }

    if ([INGREDIENT, ETAPE, USTENSILE].includes(type)) {
      PortOut = (
        <PortWidget
          className="right-0"
          engine={this.props.engine}
          port={this.props.node.getPort("out")}
        >
          <div className="default-port right-port">S</div>
        </PortWidget>
      );
    }

    let Delete;
    if (this.props.node.options.allowDelete) {
      Delete = (
        <div className="close">
          <CloseIcon
            className="m-auto"
            fill="white"
            style={{ width: "16px", height: "16px" }}
            onClick={this.removeMe}
          />
        </div>
      );
    }
    let color = "linear-gradient(45deg, "+this.props.node.options.color+","+ this.props.node.options.color+"E0,"+this.props.node.options.color+" )"
    return (
      <div className="custom-node-border">
        <div
          className={
            type === INGREDIENT || type === ETAPE
              ? "custom-node custom-node-extended"
              : "custom-node"
          }
          // style={{ backgroundColor: this.props.node.options.color }}
          style={{ backgroundImage: color }}
          tabIndex="1"
        >
          {QT}
          {Seq}
          <div className="abs">
            <div className="nodeName">{this.props.node.options.name}</div>{" "}
          </div>
          {Delete}

          {PortIn}
          {PortOut}
        </div>
      </div>
    );
  }
}
