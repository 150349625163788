import * as React from "react";
import {
  AbstractReactFactory,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams";
import { LabelModel } from "@projectstorm/react-diagrams";
import {
  BaseModelOptions,
  DeserializeEvent,
} from "@projectstorm/react-canvas-core";

import styled from "@emotion/styled";

export default class EditableLabelFactory extends AbstractReactFactory<
  EditableLabelModel,
  DiagramEngine
> {
  constructor() {
    super("editable-label");
  }

  generateModel(): EditableLabelModel {
    return new EditableLabelModel();
  }

  generateReactWidget(
    event: GenerateWidgetEvent<EditableLabelModel>
  ): JSX.Element {
    return <EditableLabelWidget model={event.model} />;
  }
}

export interface EditableLabelOptions extends BaseModelOptions {
  value?: string;
}

export class EditableLabelModel extends LabelModel {
  value: string;

  constructor(options: EditableLabelOptions = {}) {
    super({
      ...options,
      type: "editable-label",
    });
    this.value = options.value || "";
  }

  serialize() {
    return {
      ...super.serialize(),
      value: this.value,
    };
  }

  deserialize(event: DeserializeEvent<this>): void {
    super.deserialize(event);
    this.value = event.data.value;
  }
}

export interface FlowAliasLabelWidgetProps {
  model: EditableLabelModel;
}

namespace S {
  // NOTE: this CSS rules allows to interact with elements in label
  export const Label = styled.div`
    user-select: none;
    pointer-events: auto;
    // width: 10px;
  `;
}

// now we can render all what we want in the label
export const EditableLabelWidget: React.FunctionComponent<
  FlowAliasLabelWidgetProps
> = (props) => {
  const [str, setStr] = React.useState(props.model.value);

  return (
    <S.Label>
      <input
        value={str}
        style={{ width: "60px", textAlign: "center" }}
        onChange={(event) => {
          const newVal = event.target.value;

          // update value both in internal component state
          setStr(newVal);
          // and in model object
          props.model.value = newVal;

          //fire the model 
          props.model.getParentCanvasModel().fireEvent({
            link: props.model.getParent(),
            isCreated:false
          },
          "linksUpdated"
        );
        }}
        placeholder="timing"
      />
    </S.Label>
  );
};
