import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

namespace S {
  export const Expand = css`
    html,
    body,
    #root {
      height: 100%;
    }
  `;

  export const Header = styled.div`
    background-color: white;
    // height: 100%;
    margin: 20px;
    box-shadow: 0 2px 4px 0 rgb(0 0 82 / 15%);
    border-radius: 8px;
  `;

  export const Toolbar = styled.div`
    padding: 5px;
    display: flex;
    flex-shrink: 0;
    overflow: auto;
  `;

  export const Menu = styled.div`
    // padding: 5px;
    display: flex;
    flex-shrink: 0;
    // background: #ececf1;
  `;

  export const Content = styled.div`
    flex-grow: 1;
    height: 100%;

    // position:absolute;
    // left:0px;
    // width:100vw;
  `;

  export const Container = styled.div`
    // background: black;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    border-top: solid 2px white;
  `;
}

export interface CookWorkspaceWidgetProps {
  buttons?: any;
  Menu?: any;
}

export const CookButton = styled.button`
  align-items: center;
  background-color: var(--b0);
  border: none;
  outline: none;
  display: flex;
  width: 60px;
  &:hover {
    color: black;
  }
`;


export class CookWorkspaceWidget extends React.Component<CookWorkspaceWidgetProps> {
  render() {
    return (
      <S.Container>
        <S.Header>
          <S.Menu>{this.props.Menu}</S.Menu>
          <S.Toolbar>{this.props.buttons}</S.Toolbar>
        </S.Header>
        <S.Content>{this.props.children}</S.Content>
      </S.Container>
    );
  }
}
