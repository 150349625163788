import { Component } from "react";
import { Container, Form } from "react-bootstrap";
import "./Quicks.scss";
import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import Shortcuts from "../shortcuts/shortcuts";

class QuickSearch extends Component {

  render() {
    let showHideClassName = this.props.show
      ? "modal display-block"
      : "modal display-none";
    return (
      <div id="searchmodal" className={showHideClassName}>
        <div className="modal-main">
          <Container fluid>
            <div
              className="row align-items-center text-center"
              style={{ minHeight: "3.5rem" }}
            >
              <Form id="sbar" className="d-inline-flex col mt-auto mb-auto">
                <div className="ml-auto mr-auto d-flex">
                  <SearchIcon
                    className="mt-auto mb-auto "
                    style={{ width: "15px", height: "15px" }}
                    fill="white"
                  />
                  <Form.Control
                    type="text"
                    placeholder="Rechercher sur CookMe"
                    className="searchform-input"
                    id="textcontrol"
                    onChange={(event) => { this.child.getshorcuts(event.target.value) }}
                    autoFocus
                  />
                  <TimesIcon
                    id="close"
                    className="mt-auto mb-auto"
                    fill="white"
                    style={{ cursor: "pointer", width: "18px", height: "18px" }}
                    onClick={this.props.handleClose}
                  />
                </div>
              </Form>
            </div>
          </Container>
        </div>
        <Shortcuts ref={instance => { this.child = instance; }} > </Shortcuts>
      </div>
    );
  }
}

export default QuickSearch;
