import { Component } from "react";
import "./Login.css";
import { Navigate } from "react-router";

var api = "";
if (process.env.NODE_ENV !== "production") {
  api =
    "http://" +
    process.env.REACT_APP_WEBAPI_HOST +
    ":" +
    process.env.REACT_APP_WEBAPI_PORT;
}

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout(){


    fetch(
      api + "/api/user/disconnect/",
      {
        method: "DELETE",
        headers: {
          'Content-type': 'application/json; charset=UTF-8' // Indicates the content 
         },
        body:JSON.stringify({"token": this.props.token})
      }
    )
     .catch(err => console.log(err))
    this.props.clearCreds()
  }

  render() {
    this.logout()
    return (
      <Navigate to="/login" />
    );
  }
}
