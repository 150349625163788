import { DiagramModel } from "@projectstorm/react-diagrams";

const INGREDIENT = "Ingredient";
// const INGREDIENT_INTERM = "Ingredient_Interm";
const USTENSILE = "Ustensile";
const ETAPE = "Etape";
const FIN = "Fin";

class Branch {
  constructor(options) {
    this.id = options.id;
    this.steps = options.steps;
    this.lastNode = options.lastNode | null;
    this.frontNode = options.lastNode | null;
    this.prevBranches = [];
    this.prevNodes = options.prevNodes | [];
    this.isleaf = options.isleaf | false;
    this.isbranched = false;

    this.getPreviousBranches = this.getPreviousBranches.bind(this);
    this.populate = this.populate.bind(this);
    this.describe = this.describe.bind(this);
    this.code = this.code.bind(this);

    if (options.steps) {
      this.populate();
    }
  }

  populate() {
    this.frontNode = this.steps.slice(-1)[0];
    this.lastNode = this.steps[0];
    this.prevNodes = this.lastNode.getPreviousNodes();
    this.isleaf = this.prevNodes.length === 0;
    this.islast = this.steps.filter((n) => n.options.type === FIN).length === 1;
  }

  getPreviousBranches(branches) {
    branches.forEach((b) => {
      if (b !== this) {
        if (this.prevNodes.includes(b.frontNode)) {
          this.prevBranches.push(b);
        }
      }
    });
  }

  describe() {
    let prevActions = this.prevBranches.map((e) => e.step).sort();
    return this.steps
      .filter((e) => e.getType() === ETAPE || e.getType() === FIN)
      .map((e) => {
        return e.describe(prevActions);
      });
  }

  code() {
    this.steps.forEach((s) => s.getconnected());
    // this.steps.concat([this.frontNode]).forEach(s => console.log(s))
  }
}

export class CookModel extends DiagramModel {
  constructor(options = {}) {
    super(options);
    this.getNodesByType = this.getNodesByType.bind(this);
    this.getModules = this.getModules.bind(this);
    this.extract_branches = this.extract_branches.bind(this);
    this.getLastNode = this.getLastNode.bind(this);

    if (this.options.HhItems)
      this.registerListener({
        linksUpdated: (event) => {
          // console.log(event)
          if (!event.isCreated) this.options.HhItems(event.entity.getModules());
          else
            event.link.registerListener({
              targetPortChanged: (ev) => {
                this.options.HhItems(
                  ev.entity.getParent().getParent().getModules()
                );
              },
            });
        },
        nodesUpdated: (e) => this.options.HhItems(e.entity.getModules()),
      });
  }

  getLastNode() {
    this.options.lastNode = this.getNodes().filter((n) =>
      Object.values(n.type === FIN)
    )[0];
  }

  getNodesByType(type) {
    return this.getNodes().filter((n) => n.getType() === type);
  }

  check_not_connected(listNodes, _port, _state) {
    return listNodes.filter((n) =>
      _state
        ? Object.values(n.getPort("in").getLinks()).length === 0
        : Object.values(n.getPort(_port).getLinks()).length > 0
    );
  }

  check_ingredients(listNodes) {
    return listNodes.filter(
      (n) => Object.values(n.getPort("in").getLinks()).length === 0
    );
  }

  check_ingredients_interm(listNodes) {
    return listNodes.filter(
      (n) => Object.values(n.getPort("in").getLinks()).length > 0
    );
  }

  getbranche(node) {
    let newB = [node];
    let nodex = node.getPreviousNodes();
    while (nodex && nodex.length === 1) {
      newB.push(nodex[0]);
      nodex = nodex[0].getPreviousNodes();
    }
    newB.reverse();
    return newB;
  }

  getsum(a) {
    return a.reduce((ps, a, idx) => ps + a * idx, 0);
  }

  orderBranches(branches) {
    let notconnected = branches.filter((b) => !b.isbranched);
    notconnected.forEach((el, idx) => (el.step = idx));
    let indexes = notconnected.length;

    branches = branches.filter((b) => b.isbranched);
    // console.log("135",branches)

    let connected = branches.map((e) => {
      return branches.map((e) => {
        return 0;
      });
    });

    branches.forEach((e) => {
      return e.prevBranches.forEach((el) => {
        connected[e.id][el.id] = 1;
      });
    });

    connected = connected.map((e, idx) => {
      return { id: branches[idx].id, connected: e };
    });

    connected.sort((a, b) =>
      this.getsum(a.connected) > this.getsum(b.connected) ? 1 : -1
    );

    branches = connected.map((c, idx) => {
      let b = branches.filter((b) => b.id === c.id)[0];
      b.step = indexes + idx + 1;
      return b;
    });
    // console.log(branches)
    return notconnected.concat(branches);
  }

  extract_branches() {
    this.getLastNode();
    let ABranches = [];
    let nodes = [this.options.lastNode];
    let bnodes = this.getNodes().filter((n) => n.isBranch());

    // console.log(bnodes);

    bnodes.forEach((n) => {
      let prevnodes = n.getPreviousNodes();
      nodes = nodes.concat(prevnodes.length ? prevnodes : [n]);
    });

    nodes.forEach((n, idx) => {
      ABranches.push(new Branch({ steps: this.getbranche(n), id: idx }));
    });

    ABranches.forEach((b) => b.getPreviousBranches(ABranches));
    ABranches.forEach((b) =>
      b.prevBranches.forEach((pb) => (pb.isbranched = true))
    );
    ABranches.filter((b) => b.islast)[0].isbranched = true;
    ABranches = this.orderBranches(ABranches);

    ABranches.forEach((b) => b.code());
    // console.log(ABranches);

    return ABranches;
  }

  isempty(l_maps) {
    return (
      l_maps.length == 1 &&
      l_maps[0].frontNode.getType() === FIN &&
      l_maps[0].lastNode.getType() === FIN
    );
  }

  getModules() {
    let maps = this.extract_branches();
    if (!this.isempty(maps)) {
      let ingredients = this.getNodesByType(INGREDIENT);
      return {
        INGREDIENT: this.check_ingredients(ingredients).map((i) => {
          return {
            name: i.options.name,
            qte: i.options.qte,
            unit: (i.options.unit==="Pièce")?"":i.options.unit,
          };
        }),
        INGREDIENT_INTERM: this.check_ingredients_interm(ingredients).map(
          (i) => {
            return {
              name: i.options.name,
              qte: i.options.qte,
              unit: (i.options.unit==="Pièce")?"":i.options.unit,
            };
          }
        ),
        USTENSILE: this.getNodesByType(USTENSILE).map((i) => {
          return { name: i.options.name };
        }),
        ETAPE: maps.map((b) => {
          return b.describe();
        }),
      };
    } else {
      return({INGREDIENT:[],USTENSILE:[],ETAPE:[],INGREDIENT_INTERM:[]})
    }
  }
}
