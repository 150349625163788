import React, { Component } from "react";
import { Container } from "react-bootstrap";
import createEngine, {
  DagreEngine,
  DefaultDiagramState,
} from "@projectstorm/react-diagrams";

// import { Col, Row } from "react-bootstrap";
// import { CookNodeModel } from "./CookNode/CookNodeModel";
import { CookModel } from "./CookModel";
import { CookNodeFactory } from "./CookNode/CookNodeFactory";
import { CookNodeModel } from "./CookNode/CookNodeModel";
import EditableLabelFactory from "./CookLink/EditableLabel.tsx";
import { CookPortFactory } from "./CookPort/CookPortFactory.tsx";
import { CookLinkFactory } from "./CookLink/CookLinkFactory.tsx";
import { DetailsRecette } from "./DetailsRecette";
import {
  Iconsave,
  Iconfolder,
  Iconaligncenter,
  Iconalignleft,
  Iconclean,
} from "../../assets/icons/";

import {
  CookButton,
  CookWorkspaceWidget,
} from "./CookWorkspace/CookWorkspaceWidget.tsx";

import {
  CanvasWidget,
  Action,
  InputType,
} from "@projectstorm/react-canvas-core";

import MenuAddRecipe from "./Menu/MenuAddRecipe";
import { CookCanvasWidget } from "./CookWorkspace/CookCanvasWidget.tsx";

import "./Recipes.css";

const INGREDIENT = "Ingredient";
const USTENSILE = "Ustensile";
const ETAPE = "Etape";
const FIN = "Fin";

class CustomDeleteItemsAction extends Action {
  constructor(options = {}) {
    options = {
      keyCodes: [46],
      ...options,
    };
    super({
      type: InputType.KEY_DOWN,
      fire: (event) => {
        if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
          const selectedEntities = this.engine.getModel().getSelectedEntities();
          if (selectedEntities.length > 0) {
            selectedEntities.forEach((model) => {
              if (model.options.type !== FIN && !model.isLocked()) {
                model.remove();
              }
            });
            this.engine.repaintCanvas();
          }
        }
      },
    });
  }
}

export default class AddRecipe extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.state = {
      engine: undefined,
      model_id: "",
      recette: "",
      Ingredients: [],
      Ustensiles: [],
      Etapes: [],
      visible: false,
    };
    this.onHandleAddNode = this.onHandleAddNode.bind(this);
    this.initEngine = this.initEngine.bind(this);
    this.onModelChanges = this.onModelChanges.bind(this);

    this.engine = createEngine({ registerDefaultDeleteItemsAction: false });
    // create a diagram model
    const model = new CookModel({ HhItems: this.onModelChanges });
    this.engine.setModel(model);
  }

  initEngine = () => {
    this.engine
      .getNodeFactories()
      .registerFactory(new CookNodeFactory(INGREDIENT));

    this.engine
      .getNodeFactories()
      .registerFactory(new CookNodeFactory(USTENSILE));

    this.engine.getNodeFactories().registerFactory(new CookNodeFactory(ETAPE));
    this.engine.getNodeFactories().registerFactory(new CookNodeFactory(FIN));

    this.engine.getPortFactories().registerFactory(new CookPortFactory());

    this.engine
      .getLinkFactories()
      .registerFactory(new CookLinkFactory("cooklink"));
    this.engine
      .getLinkFactories()
      .registerFactory(new CookLinkFactory("cooklink-labeled"));
    this.engine.getLabelFactories().registerFactory(new EditableLabelFactory());
    this.engine.maxNumberPointsPerLink = 0;

    this.engine
      .getActionEventBus()
      .registerAction(new CustomDeleteItemsAction());

    const state = this.engine.getStateMachine().getCurrentState();
    if (state instanceof DefaultDiagramState) {
      state.dragNewLink.config.allowLooseLinks = false;
    }

    // this.onHandleAddNode(null, INGREDIENT, INGREDIENT);
    // this.onHandleAddNode(null, USTENSILE, USTENSILE);
    // this.onHandleAddNode(null, ETAPE, ETAPE);
    // this.onHandleAddNode(null, ETAPE, "Mixer")
    this.onHandleAddNode(null, FIN, "Servir");
  };

  onModelChanges = (model) => {
    // console.log(model);
    this.setState({
      Ingredients: model.INGREDIENT,
      Ustensiles: model.USTENSILE,
      Etapes: model.ETAPE,
      visible:
        model.INGREDIENT.length || model.USTENSILE.length || model.ETAPE.length,
    });
    // console.log(this.engine.getLinkFactories().getFactory<PathFindingLinkFactory>(PathFindingLinkFactory.NAME).calculateRoutingMatrix());
  };

  componentDidMount() {
    this.initEngine();
  }

  onHandleAddNode(e, type, nodename) {
    if (nodename) {
      nodename = nodename.charAt(0).toUpperCase() + nodename.slice(1);

      let node = new CookNodeModel({ name: nodename, type: type });
      switch (type) {
        case INGREDIENT:
          node.setPosition(5, 50);
          break;
        case USTENSILE:
          node.setPosition(300, 50);
          break;
        case ETAPE:
          node.setPosition(600, 50);
          break;
        case FIN:
          node = new CookNodeModel({
            name: nodename,
            type: type,
            allowDelete: false,
          });
          node.setPosition(200, 200);
          break;
        default:
          return;
      }
      const model = this.engine.getModel();

      model.addNode(node);
      this.engine.setModel(model);
      this.engine.repaintCanvas();
      // if (
      //   this.IngredientsNodes.length ||
      //   this.UstensilesNodes.length ||
      //   this.EtapesNodes.length
      // )
      //   this.setState({ recette: JSON.stringify(model.serialize()) });
    }
  }

  autoDistribute = (Dir) => {
    let Dagreengine = new DagreEngine({
      graph: {
        rankdir: Dir === "H" ? "LR" : "TD",
        marginx: 0,
        marginy: 0,
      },
      includeLinks: false,
    });

    Dagreengine.redistribute(this.engine.getModel());
    this.engine.zoomToFitNodes({ margin: 50 });
    // this.onModelChanges(this.engine.getModel().getModules())
    this.engine.repaintCanvas();
  };

  clearRecipt = (e) => {
    const model = new CookModel({ HhItems: this.onModelChanges });
    this.engine.setModel(model);
    this.onHandleAddNode(null, FIN, "Servir");
    this.setState({ model_id: ""});


  };

  renameModel = (e) => {
    this.setState({ model_id: e.target.value });
  };

  UploadRecipe = (e) => {
    const fileReader = new FileReader();
    if (e.target.files && e.target.files[0]) {
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        // Force to reset before populating
        var model = new CookModel({ HhItems: this.onModelChanges });
        this.engine.setModel(model);

        var data = JSON.parse(e.target.result);
        this.setState({ model_id: data.name });
        model.deserializeModel(data, this.engine);
        // this.engine.setModel(model);
        this.engine.repaintCanvas();

        setTimeout(() => {
          this.onModelChanges(this.engine.getModel().getModules());
        }, 1000);
        // ?? il y a un truc bizarre
      };
    }
  };

  downloadFile = () => {
    if (this.state.model_id === "") {
      alert("Indiquer le nom de la recette");
      return;
    }
    // create file in browser
    const fileName = this.state.model_id;
    let model = this.engine.getModel().serialize();
    model.name = this.state.model_id;
    const json = JSON.stringify(model);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    // console.log(model);
  };

  handleFileClick = (event) => {
    this.hiddenFileInput.current.click();
  };

  render() {
    return (
      <Container fluid className="diagram-container">
        <CookWorkspaceWidget
          Menu={
            <>
              <MenuAddRecipe
                onHandleChange={this.onHandleAddNode}
              ></MenuAddRecipe>
            </>
          }
          buttons={
            <>
             <CookButton onClick={this.clearRecipt}>
                    <Iconclean
                      className="mt-auto mb-auto ml-auto mr-auto "
                      style={{ width: "30px", height: "30px" }}
                    />
                  </CookButton>
                   <CookButton onClick={this.handleFileClick} >
                    <Iconfolder
                      className="mt-auto mb-auto ml-auto mr-auto "
                      style={{ width: "25px", height: "25px" }}
                    />
                  </CookButton>
                  <CookButton onClick={this.downloadFile}>
                    <Iconsave
                      className="mt-auto mb-auto ml-auto mr-auto "
                      style={{ width: "25px", height: "25px" }}
                    />
                  </CookButton>
                  <input
                    placeholder="Nom de la recette"
                    onChange={this.renameModel}
                    style={{
                      minWidth: "200px",
                      width: "100%",
                      // height: "35px",
                      textIndent: "10px",
                      border:"none",
                      marginLeft:"10px",
                      borderLeft: "1px solid rgba(0,0,0,.1)"
                    }}
                    defaultValue={this.state.model_id}
                  />
                  <input
                    type="file"
                    onChange={this.UploadRecipe}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    ref={this.hiddenFileInput}
                    style={{ display: "none" }}
                  />
                 
                 
                 
                  <CookButton onClick={(e) => this.autoDistribute("V")} style={{ borderLeft: "1px solid rgba(0,0,0,.1)"}}>
                    <Iconaligncenter
                      className="mt-auto mb-auto ml-auto mr-auto "
                      style={{ width: "25px", height: "25px" }}
                    />
                  </CookButton>
                  <CookButton onClick={(e) => this.autoDistribute("H")}>
                    <Iconalignleft
                      className="mt-auto mb-auto ml-auto mr-auto "
                      style={{ width: "25px", height: "25px" }}
                    />
                  </CookButton>

            </>
          }
        >

          <CookCanvasWidget>
            <CanvasWidget engine={this.engine} background="rgb(0, 0, 0)" />
          </CookCanvasWidget>
          <DetailsRecette
            Ingredients={this.state.Ingredients}
            Ustensiles={this.state.Ustensiles}
            Etapes={this.state.Etapes}
          ></DetailsRecette>
        </CookWorkspaceWidget>
      </Container>
    );
  }
}
