import { Component } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

function initshortcuts() {
  return [
    {
        query: "test",
    },
    {
        query: "test2",
    },
    {
        query: "test3",
    },
    {
        query: "test4",
    },
    {
        query: "test5",
    },
  ];
}
class Shortcuts extends Component {
  constructor(props) {
    super(props);
    this.state={
        shortcuts : []
    }
    this.getshorcuts = this.getshorcuts.bind(this);
  }

  getshorcuts(query) {
        this.setState({shortcuts : (query === "") ? [] : initshortcuts()});
  }

  render() {
    if (this.state.shortcuts.length > 0) {
      return (
        <div className="searchform-shortcuts">
          <div className="searchform-shortcuts-content">
            <div className="searchform-shortcuts-header animated ">
              Suggestions de recherche
            </div>
            <ul className="searchresults-shortcuts-list" id="defaultlinks">
              {this.state.shortcuts.map((sch, index) => (
                <li
                  className="searchresults-shortcuts-link animated"
                  key={index}
                >
                  <SearchIcon
                    className="mt-auto mb-auto "
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "0.5rem",
                    }}
                    fill="gray"
                  />
                  <a href={window.location.origin + "/search?" + sch.query}>
                    {sch.query}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default Shortcuts;
