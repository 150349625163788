import { DefaultLinkModel } from "@projectstorm/react-diagrams";

import { EditableLabelModel } from "./EditableLabel.tsx";

export class CookLinkModel extends DefaultLinkModel {
  constructor(options, type?) {
    super({
      type: type,
      width: 4,
      ...options,
    });

    if (this.options.type === "cooklink-labeled") {
      this.addLabel(
        new EditableLabelModel({
          value: "0s",
        })
      );
    }

    this.remove = this.remove.bind(this)
  }


  serialize() {
    return {
      ...super.serialize(),
      type: this.options.type,
      width: this.options.width,
      color: this.options.color,
      curvyness: this.options.curvyness,
      selectedColor: this.options.selectedColor,
    };
  }

  deserialize(event) {
    super.deserialize(event);
    this.options.color = event.data.color;
    this.options.width = event.data.width;
    this.options.curvyness = event.data.curvyness;
    this.options.selectedColor = event.data.selectedColor;
    this.options.type = event.data.type;
  }
}
