import { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { Navbar, Nav, Container } from "react-bootstrap";
import QuickSearch from "../Quicks/QuickSearch";
import { Iconsearch, Iconlayerplus } from "../../assets/icons/";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
// import { ReactComponent as SignInIcon } from "../../assets/icons/sign-in.svg";

import "./Navigation.css";

let speed = 400;

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      showAddLib: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
    // $(".selectregion").hide(speed);
    $(".nav-link").hide(speed);
    $("#searchmodal").show(speed);
    $("#textcontrol").trigger("focus");
  };

  ShowAddLib = () => {
    this.setState({ showAddLib: true });
    
  };

  hideModal = () => {
    this.setState({ show: false });
    $(".nav-link").show(speed);
    // $(".selectregion").show(speed);
    $("#searchmodal").hide(speed);
    $("#textcontrol").val("");
  };

  ToggleSideBar = () => {
    console.log("test");
    $(".mainsidebar").toggle();
  };

  collapse = (e) => {
    $(".navbar-toggler").trigger("click");
  };

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="sm"
          variant="dark"
          className="content-fluid fixed-top"
        >
          <QuickSearch
            show={this.state.show}
            handleClose={this.hideModal}
          ></QuickSearch>

          <Container id="navigation">
            <NavLink
              className="navbar-brand nav-link ml-auto mr-auto pl-5 "
              to="/"
            >
              CookMe
            </NavLink>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              data-toggle="collapse"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
            
              <Nav className="ml-auto">
              {(this.props.islogged) ? <>
                <div
                  id="search"
                  className="nav-link"
                  role="button"
                  onClick={this.showModal}
                >
                  <Iconsearch
                    className="mt-auto mb-auto "
                    style={{ width: "20px", height: "20px" }}
                    fill="white"
                  />
                </div>

                <NavLink className="nav-link" to="/" onClick={this.collapse}>
                  <HomeIcon
                    className="mt-auto mb-auto "
                    style={{ width: "22px", height: "22px" }}
                    fill="white"
                  />
                  <span>Home</span>
                </NavLink>
                
                <NavLink
                  className="nav-link"
                  to="/recette"
                  onClick={this.collapse}
                >
                  <Iconlayerplus
                    className="mt-auto mb-auto "
                    style={{ width: "20px", height: "20px" }}
                    fill="white"
                  />
                  <span>Ajouter une recette</span>
                </NavLink>

                 </>: <></>}

                {/* <NavLink
                  className="nav-link"
                  to={(!this.props.islogged)  ? "/login" : "/logout"}
                  onClick={this.collapse}
                >
                  <SignInIcon
                    className={`mt-auto mb-auto  ${ this.props.islogged ? "svg-rotate" : ""}`}
                    style={{ width: "22px", height: "22px" }}
                    fill="white"
                  />
                  <span>{!this.props.islogged ? "Login":"Logout"}</span>
                </NavLink> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default Navigation;

